import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="Symantec — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>Symantec. Making a design real... then making it better.</h1>
			<h3>
				Working with Fortified, Client of Waybury &mdash; Co-Founder & Director
				of Product Design
			</h3>
			<p>
				We worked with a San Francisco digital design agency that was tasked to
				create the next, future-focused version of Symantec's flagship suite of
				consumer facing online products. Their design team developed static
				design comps for critical screens and flows and wanted to extend their
				understanding of the user interaction and experience by having
				functional prototypes built for key areas.
			</p>
			<p>
				They looked to us to not only build a prototype of their screens, but to
				collaboratively identify and solve interaction and design issues that
				were uncovered during the prototyping process. Waybury delivered a fully
				functional, responsive prototype which was used along with the
				prototype-informed revised design to present the final concept to
				Symantec.
			</p>
		</header>
		<section>
			<figure>
				<Image alt="Redlines" filename="slide-symantec-redline.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Code" filename="slide-symantec-code.png" />
			</figure>
			<article>
				<h2>From direction to development</h2>
				<p>
					The visual design provided to Waybury set the direction, ready for
					exploration and iteration. We quickly worked to create rough
					functional wireframes of each screen so we could get an overview of
					the product's scope and commonalities. This allowed us to identify
					latent interaction challenges in the design to help frame our efforts.
					From there we focused on tuning the details of interaction design,
					applied motion design to all states and helped adapt the design to a
					tablet format. Throughout the process we identified and presented our
					adaptations and best-practice recommendations for design reviews and
					discussion.
				</p>
			</article>
		</section>
		<section>
			<figure>
				<Image alt="Prototype" filename="slide-symantec-prototype.png" />
			</figure>
			<article>
				<h2>Creating better discussions around prototypes</h2>
				<p>
					All reviews with the agency were centered around working code. Both
					teams would review the prototype progress, discuss key learnings and
					challenges then determine next steps and propose changes through
					sketches and quick Photoshop comps. Working in functional code brought
					a realism and foundation to the thinking that would have been
					incomplete using only traditional sketches and static comps. Handing a
					tablet with an interactive prototype to a team member so they could
					use their design made all the difference.
				</p>
			</article>
		</section>
	</Layout>
)
